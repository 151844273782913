import React from 'react';
import { INewsAndBlogEntry } from '../../../types/sanityPage';
import { NewsAndBlogListEntry } from './NewsAndBlogListEntry';

import * as styles from './NewsAndBlogModule.module.scss';

interface Props {
  newsAndBlogEntrys: INewsAndBlogEntry[];
}

export const NewsAndBlogList: React.VFC<Props> = ({ newsAndBlogEntrys }) => {
  return (
    <div className={styles.newsAndBlogListContainer}>
      {newsAndBlogEntrys.map((newsAndBlogEntry) => (
        <NewsAndBlogListEntry
          key={newsAndBlogEntry.title}
          newsAndBlogEntry={newsAndBlogEntry}
        />
      ))}
    </div>
  );
};
