import React, { useState } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { INewsAndBlogEntry } from '../../../types/sanityPage';

import cx from 'classnames';

import * as styles from './NewsAndBlogModule.module.scss';

import { ReadMore } from './ReadMore';
import { sanityImageUrlBuilder } from '../../../utils/sanity';

interface Props {
  newsAndBlogEntrys: INewsAndBlogEntry[];
}

export const NewsAndBlogSlideshow: React.VFC<Props> = ({
  newsAndBlogEntrys,
}) => {
  const [activeSlideNumber, setActiveSlideNumber] = useState(0);
  const activeSlide = useMemo(
    () => newsAndBlogEntrys[activeSlideNumber],
    [activeSlideNumber]
  );

  const updateActiveSlide = () => {
    const nextSlide = activeSlideNumber + 1;
    if (nextSlide >= newsAndBlogEntrys.length) {
      setActiveSlideNumber(0);
    } else {
      setActiveSlideNumber(nextSlide);
    }
  };

  useEffect(() => {
    setTimeout(updateActiveSlide, 5000);
  }, [activeSlide]);

  const slides = newsAndBlogEntrys.map((entry) => (
    <div
      aria-hidden
      className={cx(styles.slideshow, entry.title.charAt(0))}
      style={{
        backgroundImage: `url(${sanityImageUrlBuilder
          .image(entry.image.asset.url)
          .width(700)
          .url()})`,
      }}
    >
      <div className={styles.details}>
        <ReadMore link={entry.link} text={entry.title} />
      </div>
    </div>
  ));

  return slides[activeSlideNumber];
};
