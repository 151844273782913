import React from 'react';
import {
  SanityBlockChild,
  SanityBlocks,
  SanityMarks,
} from '../../types/sanity';

interface BCProps {
  blocks: SanityBlocks;
}

interface MCProps {
  blockChild: SanityBlockChild;
}

interface MEProps {
  mark: SanityMarks;
  textNode: React.ReactNode;
}

const MarkedElement: React.VFC<MEProps> = ({ mark, textNode }) => {
  switch (mark) {
    case 'strong':
      return <strong>{textNode}</strong>;

    default:
      return <>{textNode}</>;
  }
};

const MarkedContent: React.VFC<MCProps> = ({ blockChild }) => {
  return blockChild.marks.length ? (
    Array.from(new Set(blockChild.marks)).reduce(
      (acc, cur) => <MarkedElement mark={cur} textNode={acc} />,
      <React.Fragment key={blockChild.text}>{blockChild.text}</React.Fragment>
    )
  ) : (
    <>{blockChild.text}</>
  );
};

export const BlockContent: React.VFC<BCProps> = ({ blocks }) => {
  return (
    <>
      {blocks.map((block) => (
        <p
          key={block.children.map((el) => el.text).join('')}
          style={{ whiteSpace: 'pre-line', marginTop: '12px' }}
        >
          {block.children.map((blockChild) => (
            <MarkedContent key={blockChild.text} blockChild={blockChild} />
          ))}
        </p>
      ))}
    </>
  );
};
