import React from 'react';
import cx from 'classnames';
import * as styles from './NewsAndBlogModule.module.scss';

interface Props {
  link: string;
  text: string;
  textClassName?: string;
}
export const ReadMore: React.FC<Props> = ({ link, text, textClassName }) => {
  return (
    <div>
      <a
        className={cx(styles.linkText, textClassName)}
        href={link}
        referrerPolicy='no-referrer'
        target='_blank'
      >
        <h3>{text}</h3>
      </a>
      <a
        className={styles.linkButton}
        href={link}
        referrerPolicy='no-referrer'
        target='_blank'
      >
        Read more
      </a>
    </div>
  );
};
