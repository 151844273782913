import React from 'react';
import { IIconListWithImage } from '../../../types/sanityPage';
import { StandardSidePaddedContainer } from '../../common/StandardSidePaddedContainer';

import * as styles from './IconListWithImage.module.scss';

interface Props {
  iconListWithImage: IIconListWithImage;
}

export const IconListWithImage: React.VFC<Props> = ({ iconListWithImage }) => {
  return (
    <StandardSidePaddedContainer>
      <div className={styles.iconListWithImageContainer}>
        <div className={styles.iconList}>
          <div className={styles.iconListIntroduction}>
            <h2>{iconListWithImage.title}</h2>
            <p>{iconListWithImage.bread}</p>
          </div>
          <div>
            {iconListWithImage.iconList.map((iconListItem) => (
              <div className={styles.iconListElement}>
                <i className={iconListItem.icon} />
                <div>
                  <h3>{iconListItem.title}</h3>
                  <p>{iconListItem.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className={styles.imageElement}
          style={{
            backgroundImage: `url(${iconListWithImage.image.asset.url})`,
          }}
        />
      </div>
    </StandardSidePaddedContainer>
  );
};
