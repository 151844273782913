import React from 'react';
import { PageContent } from '../components/PageContent/PageContent';
import { Layout } from '../containers/Layout';
import { isSanityPageContent } from '../typeguards/pageTypeGuards';
import { SanityPage } from '../types/sanityPage';

interface Props {
  pageContext: SanityPage;
}

const Page: React.VFC<Props> = ({ pageContext }) => {
  return (
    <Layout imageHeader={pageContext.header[0]}>
      {pageContext.content.map(
        (content) =>
          isSanityPageContent(content) && (
            <PageContent key={content._type} pageContent={content} />
          )
      )}
    </Layout>
  );
};

export default Page;
