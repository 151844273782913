import React from 'react';
import { IInfoCards } from '../../../types/sanityPage';
import { InfoCard } from './InfoCard';

import * as styles from './InfoCards.module.scss';

interface Props {
  infoCards: IInfoCards;
}

export const InfoCards: React.VFC<Props> = ({ infoCards }) => {
  return (
    <div className={styles.infoCards}>
      {infoCards.infoCards.map((infoCard) => (
        <InfoCard key={infoCard.title} infoCard={infoCard} />
      ))}
    </div>
  );
};
