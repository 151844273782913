import React from 'react';

import { Link } from 'gatsby';
import { IMasionaryItem } from '../../../types/sanityPage';
import cx from 'classnames';

import * as styles from './MaisonaryGrid.module.scss';
import { sanityImageUrlBuilder } from '../../../utils/sanity';

interface Props {
  maisonaryItem: IMasionaryItem;
  reverse: boolean;
}

export const MasionaryItem: React.VFC<Props> = ({ maisonaryItem, reverse }) => {
  const link = (
    <Link
      to={`/${maisonaryItem.link.slug.current}`}
      className={styles.maisonaryGridText}
    >
      <i className={cx(styles.maisonaryGridIcon, maisonaryItem.icon)} />
      <h4>{maisonaryItem.title}</h4>
      <p>{maisonaryItem.subtitle}</p>
    </Link>
  );

  const image = (
    <div
      className={styles.maisonaryGridImage}
      style={{
        backgroundImage: `url(${sanityImageUrlBuilder
          .image(maisonaryItem.image.asset.url)
          .width(400)
          .url()})`,
      }}
    />
  );

  return (
    <>
      {reverse ? image : link}
      {reverse ? link : image}
    </>
  );
};
