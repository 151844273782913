import React, { useMemo } from 'react';
import { IMasionaryGrid, IMasionaryItem } from '../../../types/sanityPage';
import { CenterEmbossedTitle } from '../../common/CenterEmbossedTitle';
import { StandardSidePaddedContainer } from '../../common/StandardSidePaddedContainer';

import * as styles from './MaisonaryGrid.module.scss';
import { MaisonaryGridRow } from './MaisonaryGridRow';

interface Props {
  maisonaryGrid: IMasionaryGrid;
}

export const MaisionaryGrid: React.VFC<Props> = ({ maisonaryGrid }) => {
  const maisonaryItems = useMemo(
    () =>
      maisonaryGrid.maisonaryItems.reduce((acc, cur, i) => {
        if (i % 2 === 0) {
          acc.push([cur]);
        } else {
          acc[acc.length - 1].push(cur);
        }
        return acc;
      }, [] as IMasionaryItem[][]),
    [maisonaryGrid.maisonaryItems]
  );

  return (
    <StandardSidePaddedContainer>
      <div className={styles.maisonaryGridWrapper}>
        <CenterEmbossedTitle
          kicker={maisonaryGrid.kicker}
          title={maisonaryGrid.title}
        />

        <div className={styles.maisonaryGrid}>
          {maisonaryItems.map(([left, right], index) => (
            <MaisonaryGridRow
              key={left.title + right.title}
              leftMasinaryItem={left}
              rightMasinaryItem={right}
              reverse={index % 2 !== 0}
            />
          ))}
        </div>
      </div>
    </StandardSidePaddedContainer>
  );
};
