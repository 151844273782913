import React from 'react';
import { IGalleryWithTextOverlay } from '../../../types/sanityPage';
import { sanityImageUrlBuilder } from '../../../utils/sanity';
import { StandardSidePaddedContainer } from '../../common/StandardSidePaddedContainer';

import * as styles from './GalleryWithTextOvelay.module.scss';

interface Props {
  galleryWithTextOverlay: IGalleryWithTextOverlay;
}

export const GalleryWithTextOverlay: React.VFC<Props> = ({
  galleryWithTextOverlay,
}) => {
  return (
    <StandardSidePaddedContainer>
      <div className={styles.galleryWithTextOverlayContainer}>
        <p>{galleryWithTextOverlay.kicker}</p>
        <p className={styles.largeText}>
          {galleryWithTextOverlay.largeSubtitle}
        </p>
        <div className={styles.galleryGrid}>
          {galleryWithTextOverlay.images.map((image) => (
            <div key={image.title} className={styles.galleryElement}>
              <img
                src={
                  sanityImageUrlBuilder
                    .image(image.image.asset.url)
                    .width(400)
                    .url() || ''
                }
                loading='lazy'
              />
              <div className={styles.textOverlay}>
                <p>{image.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </StandardSidePaddedContainer>
  );
};
