import React from 'react';
import { isSanityPageContent } from '../../typeguards/pageTypeGuards';

import { SanityPageContent } from '../../types/sanityPage';
import { StandardSidePaddedContainer } from '../common/StandardSidePaddedContainer';
import { ArticleWithGallery } from './ArticleWithGallery/ArticleWithGallery';
import { CompanyContacts } from './CompanyContacts/CompanyContacts';
import { ContactForm } from './ContactForm/ContactForm';
import { FullWidthCta } from './FullWidthCta/FullWidthCta';
import { GalleryWithTextOverlay } from './GalleryWithTextOverlay/GalleryWithTextOverlay';
import { IconListWithImage } from './IconListWithImage/IconListWithImage';
import { InfoCards } from './InfoCards/InfoCards';
import { MaisionaryGrid } from './MaisonaryGrid/MaisionaryGrid';
import { NewsModule } from './NewsModule/NewsAndBlogModule';
import { PartnersAndCertificationsContainer } from './PartnersAndCertificationsContainer/PartnersAndCertificationsContainer';
import { ThreeBoxLayout } from './ThreeBoxLayout/ThreeBoxLayout';

interface Props {
  pageContent: SanityPageContent;
}

export const PageContent: React.VFC<Props> = ({ pageContent }) => {
  if (isSanityPageContent(pageContent)) {
    switch (pageContent._type) {
      case 'maisonaryGrid':
        return <MaisionaryGrid maisonaryGrid={pageContent} />;

      case 'threeBoxLayout':
        return <ThreeBoxLayout threeBoxLayout={pageContent} />;

      case 'newsModule':
        return <NewsModule newsModule={pageContent} />;

      case 'partnersAndCertificationsContainer':
        return (
          <PartnersAndCertificationsContainer
            partnersAndCertificationsContainer={pageContent}
          />
        );

      case 'fullWidthCTA':
        return <FullWidthCta fullWidthCta={pageContent} />;

      case 'articleWithGallery':
        return <ArticleWithGallery articleWithGallery={pageContent} />;

      case 'iconListWithImage':
        return <IconListWithImage iconListWithImage={pageContent} />;

      case 'galleryWithTextOverlay':
        return <GalleryWithTextOverlay galleryWithTextOverlay={pageContent} />;

      case 'infoCards':
        return (
          <StandardSidePaddedContainer>
            <InfoCards infoCards={pageContent} />
          </StandardSidePaddedContainer>
        );

      case 'contactForm':
        return <ContactForm contactForm={pageContent} />;

      case 'companyContacts':
        return <CompanyContacts companyContacts={pageContent} />;

      default:
        console.log(pageContent);
        //@ts-ignore
        return <div>{pageContent._type} is not implemented</div>;
    }
  } else {
    return <div>Empty object is not pagecontent</div>;
  }
};
