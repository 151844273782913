import React from 'react';

import cx from 'classnames';

import * as styles from './StandardSidePaddedContainer.module.scss';

interface Props {
  withMobilePadding?: boolean;
}

export const StandardSidePaddedContainer: React.FC<Props> = ({
  withMobilePadding,
  children,
}) => {
  return (
    <div
      className={cx(
        { [styles.mobile]: withMobilePadding },
        styles.standardSidePaddedContainer
      )}
    >
      {children}
    </div>
  );
};
