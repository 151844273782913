import React from 'react';
import { IEmployee } from '../../../types/sanityPage';

import * as styles from './CompanyContacts.module.scss';

interface Props {
  employee: IEmployee;
}

export const ContactCard: React.VFC<Props> = ({ employee }) => {
  return (
    <div className={styles.contactCard}>
      <a href={`mailto:${employee.email}`} className='material-icons'>
        email
      </a>
      <div className={styles.contactDetails}>
        <p className={styles.contactCardTitle}>{employee.name}</p>

        <p>{employee.jobTitle}</p>

        <p>Phone: {employee.phone}</p>
      </div>
    </div>
  );
};
