import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import * as styles from './header.module.scss';

import { Logo } from './Logo';
import { SanityNavigationGQL } from '../../types/sanityNavigation';
import { Navigation } from './Navigation';
import { SmallImageHeader } from './SmallImageHeader';
import { IHeader } from '../../types/sanityPage';
import { isLargeImageHeader } from '../../typeguards/pageTypeGuards';
import { LargeImageHeader } from './LargeImageHeader';

import cx from 'classnames';
import { StandardSidePaddedContainer } from '../common/StandardSidePaddedContainer';

interface Props {
  header: IHeader;
}

export const Header: React.VFC<Props> = ({ header }) => {
  const data = useStaticQuery<SanityNavigationGQL>(mainNavigationQuery);

  const navigation = (
    <nav className={styles.headerBar}>
      <Logo logoUrl={data.sanityNavigation.logo.asset.url} />
      <Navigation navigationObjects={data.sanityNavigation.navigation} />
    </nav>
  );

  switch (header._type) {
    case 'largeImageHeader':
      return (
        <LargeImageHeader imageHeader={header}> {navigation} </LargeImageHeader>
      );

    case 'smallImageHeader':
      return (
        <SmallImageHeader imageHeader={header}> {navigation}</SmallImageHeader>
      );
    case 'pureNavigationHeader':
      return (
        <div className={cx(styles.headerCommon, styles.pureNavigationHeader)}>
          {navigation}
        </div>
      );
  }
};

const mainNavigationQuery = graphql`
  query MainNavigation {
    sanityNavigation(_key: {}, title: { eq: "Main navigation" }) {
      title
      navigation {
        title
        hash
        route {
          ... on SanityPage {
            slug {
              current
            }
          }
          ... on SanityUpload {
            id
            title
            upload {
              asset {
                url
              }
            }
          }
        }
        subNavigation {
          title
          hash
          route {
            ... on SanityPage {
              id
              slug {
                current
              }
            }
            ... on SanityUpload {
              id
              title
              upload {
                asset {
                  url
                }
              }
            }
          }
        }
      }
      logo {
        asset {
          url
        }
      }
    }
  }
`;
