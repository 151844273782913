import React from 'react';
import { IFullWidthCTA } from '../../../types/sanityPage';
import { StandardSidePaddedContainer } from '../../common/StandardSidePaddedContainer';

import * as styles from './FullWidthCta.module.scss';

interface Props {
  fullWidthCta: IFullWidthCTA;
}

export const FullWidthCta: React.VFC<Props> = ({ fullWidthCta }) => {
  return (
    <StandardSidePaddedContainer>
      <div className={styles.fullWidthWrapper}>
        <div className={styles.fullWidthCta}>
          <p>{fullWidthCta.text}</p>
          <a href={fullWidthCta.url} target='_blank' rel='noreferrer'>
            {fullWidthCta.buttonText}
            <span className='material-icons'>open_in_new</span>
          </a>
        </div>
      </div>
    </StandardSidePaddedContainer>
  );
};
