import React from 'react';

import * as styles from './LeftEmbossedTitle.module.scss';

interface Props {
  kicker: string;
  title: string;
}

export const LeftEmbossedTitle: React.VFC<Props> = ({ kicker, title }) => (
  <div className={styles.leftEmbossedTitleContainer}>
    <p>{kicker}</p>
    <h2>{title}</h2>
  </div>
);
