import {
  ImageHeader,
  ILargeImageHeader,
  SanityPageContent,
} from '../types/sanityPage';

export const isLargeImageHeader = (
  imageHeader: ImageHeader
): imageHeader is ILargeImageHeader => 'kicker' in imageHeader;

export const isSanityPageContent = (
  x: SanityPageContent | {}
): x is SanityPageContent => '_type' in x;
