import React from 'react';
import { StandardSidePaddedContainer } from '../components/common/StandardSidePaddedContainer';
import { Footer } from '../components/Footer/Footer';
import { Header } from '../components/Header/Header';
import { HeaderWrapper } from '../components/Header/HeaderWrapper';
import { ImageHeader } from '../types/sanityPage';
interface Props {
  imageHeader: ImageHeader;
}

export const Layout: React.FC<Props> = ({ children, imageHeader }) => (
  <>
    <HeaderWrapper header={imageHeader} />

    {children}
    <Footer />
  </>
);
