import React, { useState } from 'react';
import { IContactForm } from '../../../types/sanityPage';
import { StandardSidePaddedContainer } from '../../common/StandardSidePaddedContainer';

import * as styles from './ContactForm.module.scss';

const initialAlertState = { isError: false, text: '' };

const initialFormState = {
  name: '',
  email: '',
  subject: '',
  text: '',
};

interface Props {
  contactForm: IContactForm;
}

export const ContactForm: React.VFC<Props> = ({ contactForm }) => {
  const [formData, setFormData] = useState(initialFormState);
  const [alert, setAlert] = useState(initialAlertState);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setAlert(initialAlertState);
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: Object.entries({ 'form-name': contactForm.name, ...formData })
        .map(
          ([key, value]) =>
            encodeURIComponent(key) + '=' + encodeURIComponent(value)
        )
        .join('&'),
    })
      .then(
        () => (
          setFormData(initialFormState),
          setAlert({
            isError: false,
            text: "Message sent, we'll get in touch with you shortly!",
          })
        )
      )
      .catch(() =>
        setAlert({
          isError: true,
          text: 'Something went wrong, please try again.',
        })
      );
  };

  return (
    <StandardSidePaddedContainer>
      <div className={styles.contactFormContainer}>
        <h3>Send us a message</h3>
        <form
          name={contactForm.name}
          data-netlify={true}
          onSubmit={handleSubmit}
          className={styles.form}
        >
          <div className={styles.inputRow}>
            <div>
              <input
                type='text'
                name='name'
                placeholder='Name'
                onChange={(event) =>
                  setFormData((curr) => ({
                    ...curr,
                    name: event.target.value,
                  }))
                }
                required
              />
            </div>

            <div>
              <input
                type='email'
                name='email'
                placeholder='Email'
                onChange={(event) =>
                  setFormData((curr) => ({
                    ...curr,
                    email: event.target.value,
                  }))
                }
                required
              />
            </div>

            <div>
              <input
                type='text'
                name='subject'
                placeholder='Subject'
                onChange={(event) =>
                  setFormData((curr) => ({
                    ...curr,
                    subject: event.target.value,
                  }))
                }
                required
              />
            </div>

            <textarea
              id='textInput'
              placeholder='Message'
              name='text'
              onChange={(event) =>
                setFormData((curr) => ({
                  ...curr,
                  text: event.target.value,
                }))
              }
              required
            />
            <button type='submit'>Send</button>
            {alert.text && <div>{alert.text}</div>}
          </div>
        </form>
      </div>
    </StandardSidePaddedContainer>
  );
};
