import React from 'react';
import { ILargeImageHeader } from '../../types/sanityPage';
import cx from 'classnames';
import * as styles from './header.module.scss';
import { sanityImageUrlBuilder } from '../../utils/sanity';

interface Props {
  imageHeader: ILargeImageHeader;
}
export const LargeImageHeader: React.FC<Props> = ({
  children,
  imageHeader,
}) => {
  return (
    <div
      className={cx(
        styles.headerCommon,
        styles.largeImageHeader,
        styles.overlay
      )}
      style={{
        backgroundImage: `url(${sanityImageUrlBuilder
          .image(imageHeader.image.asset.url)
          .width(1500)
          .url()})`,
      }}
    >
      {children}
      <h1 className={styles.imageHeaderContent}>
        <span className={styles.largeImageHeaderKicker}>
          {imageHeader.kicker}
        </span>
        <span className={styles.largeImageHeaderTitle}>
          {imageHeader.mainTitle}
        </span>
        <span aria-hidden className={styles.largeImageHeaderScrollText}>
          {imageHeader.scrollText}
        </span>
      </h1>
    </div>
  );
};
