import React from 'react';
import { ISmallImageHeader } from '../../types/sanityPage';
import * as styles from './header.module.scss';
import cx from 'classnames';
import { sanityImageUrlBuilder } from '../../utils/sanity';

interface Props {
  imageHeader: ISmallImageHeader;
}

export const SmallImageHeader: React.FC<Props> = ({
  imageHeader,
  children,
}) => (
  <div
    className={cx(
      { [styles.overlay]: imageHeader.title },
      styles.headerCommon,
      styles.smallImageHeader
    )}
    style={{
      backgroundImage: `url(${sanityImageUrlBuilder
        .image(imageHeader.headerImage.asset.url)
        .width(1200)
        .url()})`,
    }}
  >
    {children}
    <div className={styles.imageHeaderContent}>
      <h1>{imageHeader.title}</h1>
    </div>
  </div>
);
