import React from 'react';
import { INewsModule } from '../../../types/sanityPage';
import { CenterEmbossedTitle } from '../../common/CenterEmbossedTitle';
import { NewsAndBlogList } from './NewsAndBlogList';

import * as styles from './NewsAndBlogModule.module.scss';
import { NewsAndBlogSlideshow } from './NewsAndBlogSlideshow';

interface Props {
  newsModule: INewsModule;
}

export const NewsModule: React.VFC<Props> = ({ newsModule }) => {
  return (
    <div className={styles.newsModuleWrapper}>
      <CenterEmbossedTitle
        kicker={newsModule.kicker}
        title={newsModule.title}
      />
      <div className={styles.newsModuleContainer}>
        <NewsAndBlogSlideshow
          newsAndBlogEntrys={newsModule.newsAndBlogEntrys}
        />
        <NewsAndBlogList newsAndBlogEntrys={newsModule.newsAndBlogEntrys} />
      </div>
    </div>
  );
};
