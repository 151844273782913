import React from 'react';

import * as styles from './CenterEmbossedTitle.module.scss';

interface Props {
  kicker: string;
  title: string;
}

export const CenterEmbossedTitle: React.VFC<Props> = ({ kicker, title }) => {
  return (
    <div className={styles.centerEmbossedTitleContainer}>
      <p>{kicker}</p>
      <h2>{title}</h2>
    </div>
  );
};
