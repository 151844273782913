import React, { useState } from 'react';
import { isNavigationRoutePage } from '../../typeguards/navigation';
import { NavigationObject } from '../../types/sanityNavigation';
import { Link } from 'gatsby';
import { HOME_SLUG } from '../../constants';
import { useLocation } from '@reach/router';
import cx from 'classnames';

import * as styles from './header.module.scss';

interface Props {
  navigationObjects: NavigationObject[];
}

export const Navigation: React.VFC<Props> = ({ navigationObjects }) => {
  const location = useLocation();
  const [showNavigation, setShowNavigation] = useState(false);
  return (
    <>
      <button
        className={cx(styles.hamburgerButton)}
        onClick={() => setShowNavigation((curr) => !curr)}
      >
        menu
      </button>
      <div
        className={cx(styles.navigation, {
          [styles.displayFlex]: showNavigation,
        })}
      >
        {navigationObjects.map((navigationObject) => {
          const isActive =
            isNavigationRoutePage(navigationObject.route) &&
            (location.pathname.includes(navigationObject.route.slug.current) ||
              (location.pathname === '/' && navigationObject.title === 'Home'));
          return (
            <div key={navigationObject.title} className={styles.navigationItem}>
              <NavigationLink
                {...(isActive && { className: styles.activeLink })}
                to={navigationObject}
              />

              {navigationObject.subNavigation.length > 0 && (
                <>
                  <ul>
                    {navigationObject.subNavigation?.map(
                      (subNavigationObject) => (
                        <li key={subNavigationObject.title}>
                          <NavigationLink to={subNavigationObject} />
                        </li>
                      )
                    )}
                  </ul>
                </>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

const NavigationLink: React.VFC<{ to: NavigationObject; className?: string }> =
  ({ to, className }) =>
    isNavigationRoutePage(to.route) ? (
      <Link
        className={className}
        to={`/${
          to.route.slug.current === HOME_SLUG ? '' : to.route.slug.current
        }`}
      >
        {to.title}
      </Link>
    ) : (
      <a
        target='_blank'
        referrerPolicy='no-referrer'
        href={to.route.upload.asset.url}
      >
        {to.title}
      </a>
    );
