import React from 'react';
import { IThreeBoxLayout } from '../../../types/sanityPage';
import cx from 'classnames';

import * as styles from './ThreeBoxLayout.module.scss';
import { sanityImageUrlBuilder } from '../../../utils/sanity';
import { StandardSidePaddedContainer } from '../../common/StandardSidePaddedContainer';

interface Props {
  threeBoxLayout: IThreeBoxLayout;
}

export const ThreeBoxLayout: React.VFC<Props> = ({ threeBoxLayout }) => {
  return (
    <StandardSidePaddedContainer>
      <div className={styles.threeBoxLayoutContaier}>
        <div className={styles.leftBox}>
          <div className={styles.textBox}>
            <h3>{threeBoxLayout.titleLeft}</h3>
            <p>{threeBoxLayout.textLeft}</p>
            <h3>{threeBoxLayout.titleRight}</h3>
            <p>{threeBoxLayout.textRight}</p>
          </div>
        </div>
        <div
          className={styles.centerBox}
          style={{
            backgroundImage: `url(${sanityImageUrlBuilder
              .image(threeBoxLayout.image.asset.url)
              .width(700)
              .url()})`,
          }}
        ></div>
      </div>
    </StandardSidePaddedContainer>
  );
};
