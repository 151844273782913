// extracted by mini-css-extract-plugin
export var headerWrapper = "header-module--headerWrapper--3fPij";
export var headerCommon = "header-module--headerCommon--x3wRa";
export var largeImageHeader = "header-module--largeImageHeader--zJTrT";
export var largeImageHeaderKicker = "header-module--largeImageHeaderKicker--7vqJ1";
export var largeImageHeaderTitle = "header-module--largeImageHeaderTitle--3xoT5";
export var largeImageHeaderScrollText = "header-module--largeImageHeaderScrollText--8oigG";
export var smallImageHeader = "header-module--smallImageHeader--xPpLQ";
export var imageHeaderContent = "header-module--imageHeaderContent--Qlpdy";
export var pureNavigationHeader = "header-module--pureNavigationHeader--e1WmI";
export var overlay = "header-module--overlay--OiDmn";
export var headerBar = "header-module--headerBar---i3++";
export var logo = "header-module--logo--U6OGT";
export var navigation = "header-module--navigation--26LQH";
export var hamburgerButton = "header-module--hamburgerButton--jC04E";
export var navigationItem = "header-module--navigationItem--tZWCh";
export var activeLink = "header-module--activeLink--zfvs3";
export var displayFlex = "header-module--displayFlex--cSy5M";