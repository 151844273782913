import React from 'react';
import { INewsAndBlogEntry } from '../../../types/sanityPage';

import * as styles from './NewsAndBlogModule.module.scss';
import { ReadMore } from './ReadMore';

interface Props {
  newsAndBlogEntry: INewsAndBlogEntry;
}

export const NewsAndBlogListEntry: React.VFC<Props> = ({
  newsAndBlogEntry,
}) => {
  return (
    <div className={styles.newsAndBlogListEntry}>
      <ReadMore
        link={newsAndBlogEntry.link}
        text={newsAndBlogEntry.title}
        textClassName={styles.newsAndBlogListEntryText}
      />
    </div>
  );
};
