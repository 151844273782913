import React from 'react';
import { IArticleWithGallery } from '../../../types/sanityPage';
import { sanityImageUrlBuilder } from '../../../utils/sanity';
import { BlockContent } from '../../common/BlockContent';
import { LeftEmbossedTitle } from '../../common/LeftEmbossedTitle';
import { StandardSidePaddedContainer } from '../../common/StandardSidePaddedContainer';
import * as styles from './ArticleWithGallery.module.scss';

interface Props {
  articleWithGallery: IArticleWithGallery;
}

export const ArticleWithGallery: React.VFC<Props> = ({
  articleWithGallery,
}) => (
  <StandardSidePaddedContainer>
    <div className={styles.articleWithGalleryContainer}>
      <div className={styles.textContainer}>
        <LeftEmbossedTitle
          title={articleWithGallery.title}
          kicker={articleWithGallery.kicker}
        />
        <text>
          <BlockContent blocks={articleWithGallery.textContent} />
        </text>
      </div>
      {articleWithGallery.images.length === 1 ? (
        <div className={styles.soloImage}>
          <img src={articleWithGallery.images[0].asset.url} />
        </div>
      ) : (
        <div className={styles.imageGrid}>
          {articleWithGallery.images.map((image) => (
            <div className={styles.imageWrapper}>
              <img
                src={sanityImageUrlBuilder.image(image.asset.url).url() || ''}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  </StandardSidePaddedContainer>
);
