import React from 'react';
import { IPartnersAndCertificationsContainer } from '../../../types/sanityPage';
import { sanityImageUrlBuilder } from '../../../utils/sanity';

import * as styles from './PartnersAndCertificationsContainer.module.scss';

interface Props {
  partnersAndCertificationsContainer: IPartnersAndCertificationsContainer;
}

export const PartnersAndCertificationsContainer: React.VFC<Props> = ({
  partnersAndCertificationsContainer: {
    background,
    partnerOrCertification,
    title,
  },
}) => {
  return (
    <div
      className={styles.partnersAndCertificationsContainer}
      style={{ backgroundImage: `url(${background.asset.url})` }}
    >
      <div className={styles.titleRow}>
        <h2>{title}</h2>
      </div>
      <div className={styles.logoRow}>
        {partnerOrCertification.map((partnerOrCertification) => (
          <img
            key={partnerOrCertification.altText}
            src={
              sanityImageUrlBuilder
                .image(partnerOrCertification.logo.asset.url)
                .height(100)
                .url() || partnerOrCertification.logo.asset.url
            }
            alt={partnerOrCertification.altText}
          />
        ))}
      </div>
    </div>
  );
};
