import React from 'react';
import cx from 'classnames';

import { IInfoCard } from '../../../types/sanityPage';

import * as styles from './InfoCards.module.scss';
import { BlockContent } from '../../common/BlockContent';

interface Props {
  infoCard: IInfoCard;
}

export const InfoCard: React.VFC<Props> = ({ infoCard }) => {
  console.log(infoCard);
  return (
    <div className={styles.infoCard}>
      <i className={cx(styles.infocardIcon, infoCard.icon)} />
      <h3>{infoCard.title}</h3>
      {<BlockContent blocks={infoCard.textContent} />}
    </div>
  );
};
