import React from 'react';
import { IMasionaryItem } from '../../../types/sanityPage';
import { MasionaryItem } from './MaisonaryItem';

interface Props {
  leftMasinaryItem: IMasionaryItem;
  rightMasinaryItem: IMasionaryItem;
  reverse: boolean;
}

export const MaisonaryGridRow: React.VFC<Props> = ({
  leftMasinaryItem,
  rightMasinaryItem,
  reverse,
}) => {
  return (
    <>
      <MasionaryItem maisonaryItem={leftMasinaryItem} reverse={reverse} />
      <MasionaryItem maisonaryItem={rightMasinaryItem} reverse={reverse} />
    </>
  );
};
