import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { SanityPartnersAndCertificationsGQL } from '../../types/sanityNavigation';
import { sanityImageUrlBuilder } from '../../utils/sanity';
import { StandardSidePaddedContainer } from '../common/StandardSidePaddedContainer';

import * as styles from './Footer.module.scss';

export const Footer: React.VFC = () => {
  const data = useStaticQuery<SanityPartnersAndCertificationsGQL>(
    SanityPartnersAndCertifications
  );
  console.log(data);
  return (
    <StandardSidePaddedContainer>
      <div className={styles.footer}>
        <h3>Partners and certifications:</h3>
        <div className={styles.footerLogos}>
          {data.allSanityPartnersAndCertifications.nodes.map(
            (sanityPartnerOrCertification) => (
              <img
                src={
                  sanityImageUrlBuilder
                    .image(sanityPartnerOrCertification.logo.asset.url)
                    .height(100)
                    .url() || ''
                }
                alt={sanityPartnerOrCertification.altText}
              />
            )
          )}
        </div>
        <p>Multi industrier, Eikeskogvegen 20, 5570 Aksdal</p>
      </div>
    </StandardSidePaddedContainer>
  );
};

const SanityPartnersAndCertifications = graphql`
  query SanityPartnersAndCertifications {
    allSanityPartnersAndCertifications {
      nodes {
        altText
        logo {
          asset {
            url
          }
        }
      }
    }
  }
`;
