import React from 'react';
import { IHeader } from '../../types/sanityPage';
import { Header } from './Header';

import * as styles from './header.module.scss';

interface Props {
  header: IHeader;
}

export const HeaderWrapper: React.VFC<Props> = ({ header }) => {
  return (
    <div className={styles.headerWrapper}>
      <Header header={header} />
    </div>
  );
};
