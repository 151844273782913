import React from 'react';
import { ICompanyContacts } from '../../../types/sanityPage';
import { StandardSidePaddedContainer } from '../../common/StandardSidePaddedContainer';

import * as styles from './CompanyContacts.module.scss';
import { ContactCard } from './ContactCard';

interface Props {
  companyContacts: ICompanyContacts;
}

export const CompanyContacts: React.VFC<Props> = ({ companyContacts }) => {
  return (
    <StandardSidePaddedContainer withMobilePadding>
      <div className={styles.companyContactsWrapper}>
        <div className={styles.companyContacts}>
          <h2>{companyContacts.title}</h2>
          <div className={styles.contactGrid}>
            {companyContacts.contacts.map((contact) => (
              <ContactCard key={contact.phone} employee={contact} />
            ))}
          </div>
        </div>
      </div>
    </StandardSidePaddedContainer>
  );
};
