import { Link } from 'gatsby';
import React from 'react';
import { sanityImageUrlBuilder } from '../../utils/sanity';
import * as styles from './header.module.scss';

interface Props {
  logoUrl: string;
}

export const Logo: React.VFC<Props> = ({ logoUrl }) => {
  const src = sanityImageUrlBuilder.image(logoUrl).url();
  if (src) {
    return (
      <Link to='/' className={styles.logo}>
        <img src={src} alt='logo' />
      </Link>
    );
  }
  return null;
};
